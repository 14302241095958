









import Vue from 'vue';
import { ApiResource } from '@/types';

export default Vue.extend({
  props: {
    balance: {
      required: true,
      type: Object as () => ApiResource.Business.Balance,
    },
  },
  computed: {
    CURRENCY_DIVISOR() {
      return 100;
    },
  },
});
