


























































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import MediaBalance from '@/components/media/Balance.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  findAccount: (id: string) => ApiResource.Business.Account | undefined,
  findBalances: (id: string) => ApiResource.Business.Balances | undefined,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    MediaBalance,
  },
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null as Error | null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      findAccount: 'business/FindAccount',
      findBalances: 'business/FindBalances',
    }),
    account(): ApiResource.Business.Account | undefined {
      return this.findAccount(this.business.id);
    },
    balances(): ApiResource.Business.Balances | undefined {
      if (!this.account) return undefined;
      return this.findBalances(this.account.id);
    },
    available(): ApiResource.Business.Balance[] {
      if (!this.balances) return [];
      return this.balances.available.data;
    },
    pending(): ApiResource.Business.Balance[] {
      if (!this.balances) return [];
      return this.balances.pending.data;
    },
    business(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
  },
  watch: {
    'business.id': {
      immediate: true,
      handler(to) {
        if (to) this.fetchBalances();
      },
    },
  },
  methods: {
    handleError(e: Error) {
      this.error = e;
    },
    async fetchBalances() {
      this.loading = true;

      this.$store.dispatch('business/GetBalances', {
        BUSINESS_ID: this.business.id,
        query: {
          cached: 'no',
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;
    },
  },
});
